
/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */
.noUi-target,
.noUi-target * {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -ms-touch-action: none;
    -ms-user-select: none;
    -moz-user-select: none;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.noUi-target {
    position: relative;
    direction: ltr;
}
.noUi-base {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1; /* Fix 401 */
    border: none;
}
.noUi-origin {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
}
.noUi-handle {
    position: relative;
    z-index: 1;
}
.noUi-stacking .noUi-handle {
    /* This class is applied to the lower origin when
       its values is > 50%. */
    z-index: 10;
}
.noUi-state-tap .noUi-origin {
    -webkit-transition: left 0.3s, top 0.3s;
    transition: left 0.3s, top 0.3s;
}
.noUi-state-drag * {
    cursor: inherit !important;
}

/* Painting and performance;
 * Browsers can paint handles in their own layer.
 */
.noUi-base {
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
}

/* Slider size and handle placement;
 */
.noUi-horizontal {
    height: 5px;
    border-radius: 10px;
}
.noUi-horizontal .noUi-handle {
    /*width: 34px;*/
    /*height: 28px;*/
    /*left: -17px;*/
    /*top: -6px;*/
    width: 15px;
    height: 15px;
    margin-left: 0;
    margin-right:0;
    margin-top: -6px;
}
.noUi-vertical {
    width: 18px;
}
.noUi-vertical .noUi-handle {
    width: 28px;
    height: 34px;
    left: -6px;
    top: -17px;
}

/* Styling;
 */
.noUi-background {
    background: #D8D8D8;
    border: none;
}
.noUi-connect {
    background: #d4df79;
    -webkit-transition: background 450ms;
    transition: background 450ms;
}
.noUi-origin {
    border-radius: 2px;
}

.noUi-target {
    padding: 0 15px 0 0;
    margin:10px 0 15px 0;
    /*border-radius: 4px;*/
    /*border: 1px solid #becddb;*/
    /*box-shadow: inset 0 1px 1px #F0F0F0, 0 3px 6px -5px #BBB;*/
}
.noUi-target.noUi-connect {
    box-shadow: inset 0 0 3px rgba(51,51,51,0.45), 0 3px 6px -5px #BBB;
}

/* Handles and cursors;
 */
.noUi-dragable {
    cursor: w-resize;
}
.noUi-vertical .noUi-dragable {
    cursor: n-resize;
}
.noUi-handle {
    border-radius: 50%;
    background: var(--color-primary);
    cursor: default;
}

.noUi-active {
}

/* Handle stripes;
 */
/*.noUi-handle:before,*/
/*.noUi-handle:after {*/
    /*content: "";*/
    /*display: block;*/
    /*position: absolute;*/
    /*height: 14px;*/
    /*width: 1px;*/
    /*background: #E8E7E6;*/
    /*left: 14px;*/
    /*top: 6px;*/
/*}*/
/*.noUi-handle:after {*/
    /*left: 17px;*/
/*}*/
/*.noUi-vertical .noUi-handle:before,*/
/*.noUi-vertical .noUi-handle:after {*/
    /*width: 14px;*/
    /*height: 1px;*/
    /*left: 6px;*/
    /*top: 14px;*/
/*}*/
/*.noUi-vertical .noUi-handle:after {*/
    /*top: 17px;*/
/*}*/

/* Disabled state;
 */
[disabled].noUi-connect,
[disabled] .noUi-connect {
    background: #B8B8B8;
}
[disabled].noUi-origin,
[disabled] .noUi-handle {
    cursor: not-allowed;
}

.noUi-tooltip {
    position: absolute;
    top: 100%;
    left: 50%;
    margin-top: 10px;
    transform: translateX(-50%) translateY(10px);
    background: #ffffff;
    box-shadow: 0 0 5px rgba(0,0,0,.3);
    padding: 1px 8px;
    opacity: 0;
    transition: all 120ms ease 1000ms;
    border-radius: 2px;
    text-align: center;
    min-width: 20px;
}
.noUi-tooltip:after {
    content: '';
    position: absolute;
    top: -5px;
    left: 50%;
    margin-left: -5px;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 5px 5px 5px;
    border-color: transparent transparent #fff transparent;
}
.noUi-handle:hover .noUi-tooltip {
    opacity: 1;
    transform: translateX(-50%) translateY(0);
    transition: all 120ms ease;
}